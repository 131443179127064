html,
body {
  margin: 0;
  width: unset;
  height: unset;
}

ul {
  list-style: none;
  margin: unset;
  padding: unset;
}

input {
  padding: unset;
  border: unset;
  outline-style: unset;
  box-sizing: border-box;
  background: unset;
  margin: unset;

  &:focus {
    outline: unset;
  }
}

button {
  background-color: transparent;
  padding: unset;
  border: unset;
  font: inherit;
  color: inherit;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none 0;
  }

  &:hover {
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: unset;
}

img:not([src]) {
  content: url(assets/img/no-src.png);
}